<template>
  <div>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      :data="tableData"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="400px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="70" align="center" />
      <el-table-column label="申请单号" min-width="140">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: 'details',
              query: {disposeCode:scope.row.disposeCode},
              append:true
            }"
            style="color: #1890ff"
          >{{
            scope.row.disposeCode
          }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="关联单号" />

      <el-table-column label="处置类型">
        <template slot-scope="scope">
          <span v-if="scope.row.disposeType==1">折价</span>
          <span v-if="scope.row.disposeType==2">处置</span>
        </template>
      </el-table-column>
      <el-table-column prop="warehouseCode" label="处置仓库" />
      <el-table-column prop="applyDate" label="申请日期" />
      <el-table-column prop="approveDate" label="审批日期" />
      <el-table-column prop="outboundDate" label="出库日期" />
      <el-table-column prop="totalQuantity" label="处置数量" />
      <el-table-column prop="outboundQuantity" label="实际出库数量" />
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status==1">待审核</span>
          <span v-if="scope.row.status==2">审核通过</span>
          <span v-if="scope.row.status==3">驳回</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    modify({ applyId, historyId }) {
      this.$router.push({
        path: 'add',
        append: true,
        query: { applyId, historyId }
      })
    },
    view({ applyId, historyId }) {
      this.$router.push({
        path: 'details',
        append: true,
        query: { applyId, historyId }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
