<template>
  <!-- 处置申请 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item>
              <el-select slot="label" v-model="form.queryType" :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  value="1"
                  label="处置单号"
                />
                <el-option
                  value="2"
                  label="出库单号"
                />
              </el-select>
              <el-input v-model="form.receiptId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置仓库">
              <el-select v-model="form.warehouseCode" filterable style="width:100%" clearable :placeholder="$t('page.selectPlaceholder')" @change="isFilter">
                <el-option
                  v-for="item in warehouseList"
                  :key="item.warehouseCode"
                  :value="item.warehouseCode"
                  :label="item.warehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置类型">
              <el-select v-model="form.disposeType" :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  :value="1"
                  label="折价"
                />
                <el-option
                  :value="2"
                  label="报废"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人">
              <el-input v-model="form.proposerName" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="申请日期">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                value-format="yyyy-MM-dd "
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="mb-2">
          <el-button type="primary"><router-link to="added" append>申请折价/报废</router-link></el-button>
          <!-- <el-button type="primary"><router-link to="added" append>申请内购</router-link></el-button> -->
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-row>
      <el-tabs v-model="form.approveStatus" type="card" @tab-click="queryClick(1)">
        <el-tab-pane label="待审批" name="1">
          <DisposalTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="审批通过" name="2">
          <DisposalTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="审批驳回" name="3">
          <DisposalTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="待出库" name="4">
          <DisposalTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="出库中" name="5">
          <DisposalTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="出库完成" name="6">
          <DisposalTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="全部" name=" ">
          <DisposalTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
      </el-tabs>
    </el-row>

  </div>
</template>
<script>
import DisposalTable from './components/disposalTable'
import { pageDispose, isWarehouseList } from '@/api/disposal-apply'

import { Mixin } from '@/mixin/mixin.js'
export default {
  components: { DisposalTable },
  mixins: [Mixin],
  data() {
    return {
      form: {
        queryType: '1',
        proposerName: '',
        approveStatus: ' ',
        warehouseCode: '',
        date: [],
        disposeType: '' // 处置类型
      },
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      warehouseList: [],
      showRow: false, // 切换显示input
      tableLoading: false,
      tableData: []
    }
  },
  computed: {
    queryParams() {
      const [applyStartDate = '', applyEndDate = ''] = this.form.date || []
      const { approveStatus, disposeType, outboundCode, proposerName, queryType, warehouseCode, receiptId } = this.form
      const queryCode = queryType === '1' ? 'disposeCode' : 'code'
      return Object.assign({}, this.pager, { applyStartDate, applyEndDate, approveStatus,
        [queryCode]: receiptId, disposeType, outboundCode, proposerName, queryType, warehouseCode })
    }
  },

  mounted() {
    this._pageDispose(this.queryParams)
    this._isWarehouseList()
  },
  methods: {
    async _isWarehouseList() {
      console.log('ererer')
      const { datas } = await isWarehouseList()
      this.warehouseList = datas
    },
    queryClick() { // 点击查询获取信息
      this.pager.current = 1
      this._pageDispose(this.queryParams)
    },
    resetClick(val) { // 点击重置清空文本框信息
      this.form = {
        queryType: '1',
        proposerName: '',
        approveStatus: ' ',
        warehouseCode: '',
        date: [],
        disposeType: '' // 处置类型
      }
      this._pageDispose(this.queryParams)
    },
    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },
    async _pageDispose(params) {
      try {
        this.loading = true
        const { datas: { pager, records }} = await pageDispose(params)
        this.pager = pager
        this.tableData = records
      } finally {
        this.loading = false
      }
    },
    // 入库管理详情
    goToDetails(row) {
      this.$router.push({
        path: 'details',
        append: 'true',
        query: { 'receiptId': row.receiptId, 'tableSuffix': row.tableSuffix, receiptData: JSON.stringify(row) }
      })
    }
  }
}
</script>

